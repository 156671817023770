import React from "react";
import ModamilyLogo from "../resources/images/appAssets/BrandImage.png";
import { Link } from "react-router-dom";

const ModamilyAppWelcome = () => {

  return (
    <div className="ModamilyAppWelcome__body">
      <div className="ModamilyAppWelcome__body--opacity">
        <div className="ModamilyAppWelcome__body--content">
          <img
            src={ModamilyLogo}
            alt="Modamily Logo"
            className="ModamilyAppWelcome__modamily-logo"
          />
          <div className="ModamilyAppWelcome__title-container">
            <h2 className="ModamilyAppWelcome__title">
              A Modern Way to Family
            </h2>
          </div>

          <div className="ModamilyAppWelcome__text-container">
            <p className="ModamilyAppWelcome__text">
              Connect and form a family in our safe and empowered community.
            </p>
          </div>

          <div className="ModamilyAppWelcome__button-container">
            <div className="ModamilyAppWelcome__button-container-gradient">
              <Link to="/phone-number">
                <button className="ModamilyAppWelcome__button--gradient" >
                  Create an account
                </button>
              </Link>
            </div>

            <div className="ModamilyAppWelcome__button-container-transparent">
              <Link to="/login">
                <button className="ModamilyAppWelcome__button--transparent">
                  Sign in
                </button>
              </Link>
            </div>
          </div>

          <div className="ModamilyAppWelcome__footer-text-container">
            <p className="ModamilyAppWelcome__footer-text">
              By registering, you agree to our{" "}
            </p>
            <p className="ModamilyAppWelcome__footer-text">
              Terms of Service and our Privacy Policy.
            </p>
          </div>

          <div className="ModamilyAppWelcome__footer-recover-account-link">
            <p className="ModamilyAppWelcome__footer-recover-account-link-text">
              Recover my Account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModamilyAppWelcome;
