import React from "react";
import Avatar from "react-avatar";

import { AWS_URL } from "../../config/config";
/**
 * @property {Image} image - the image to be displayed
 * @property {Boolean} isUrl - if the image is a path from AWS
 * @property {String} size - "Large", "Regular" - size of the image
 */
export default class ModamilyAppRoundImage extends React.Component {
  state = { ...this.props };

  shouldComponentUpdate = (nextProps) => {
    return nextProps.image === this.props.image;
  };

  render() {
    let { image, name, isUrl, draggableId } = this.state;

    if (this.props.image != null) {
      image = this.props.image;
    }

    let imgSizeClass = this.props.size ? this.props.size : "";
    if (draggableId) {
      return (
        <div
          id={draggableId}
          className={"roundImageContainer" + imgSizeClass}
          onClick={this.props.onClick ? this.props.onClick : null}
        >
          <Avatar
            className="roundImage"
            draggable="false"
            alt="loading"
            src={isUrl ? AWS_URL + image : image}
            name={name ? name : "Unknown"}
            round={true}
            textMarginRatio={0.15}
            textSizeRatio={3}
            unstyled={false}
            color={"#f07f46"}
            fgColor={"#4A2D4F"}
          />
        </div>
      );
    } else {
      return (
        <div
          className={"roundImageContainer" + imgSizeClass}
          onClick={this.props.onClick ? this.props.onClick : null}
        >
          <Avatar
            className="roundImage"
            draggable="false"
            alt="loading"
            src={isUrl ? AWS_URL + image : image}
            name={name ? name : "Unknown"}
            round={true}
            textMarginRatio={0.15}
            textSizeRatio={3}
            unstyled={false}
            color={"#4A2D4F"}
            fgColor={"#4A2D4F"}
          />
        </div>
      );
    }
  }
}
